import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { DataFilters, ValueSelect } from 'src/modules/types';
import { ModelCatalogueData } from 'src/modules/types/model-catalogue-data';

@Component({
  selector: 'wag-model-catalogue',
  template: `
  <mat-accordion multi="true">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
        <h2>Field selection</h2>
        </mat-expansion-panel-header>
        <wag-catalogue-selction
          [assets]="assets"
          [fields]="fields"
          (valueSelected)="valueSelected.emit($event)"
          (catalogueFetch)="catalogueFetch.emit()">
          </wag-catalogue-selction>
    </mat-expansion-panel>

    <mat-expansion-panel [expanded]="catalogueExpand">
        <mat-expansion-panel-header>
        <h2>Models</h2>
        </mat-expansion-panel-header >

        <wag-catalogue-table *ngIf="catalogueTableData"
         [catalogueTableData]="catalogueTableData"
         [enableItem]="enableItem"
         (clickEdit)="clickEdit.emit($event)"
         (clickDelete)="clickDelete.emit($event)"
         (clickDownload)="clickDownload.emit($event)"
         (clickProduction)="clickProduction.emit($event)"
         (clickArchive)="clickArchive.emit($event)"
          (clickMergeModel)="clickMergeModel.emit($event)"
         [assets]="assets$ | async"
         [fields]="fields$ | async"
         [filterData]="filterData">
        </wag-catalogue-table>
    </mat-expansion-panel>

  </mat-accordion>

  `,
  styleUrls: ['./model-catalogue.component.scss']
})
export class ModelCatalogueComponent {
  assets$ = this.sb.assets$;
  fields$ = this.sb.fields$;
  wells$ = this.sb.wells$;
  @Input() assets!: string[] | null;
  @Input() fields!: string[] | null;
  @Input() catalogueExpand!: boolean;
  @Input() filterData: any
  @Input() catalogueTableData!: ModelCatalogueData[] | null;
  @Input() enableItem!: boolean;
  @Output() valueSelected = new EventEmitter<ValueSelect>();
  @Output() catalogueFetch = new EventEmitter();
  @Output() clickEdit: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickDelete: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickProduction: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickArchive: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickDownload: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickMergeModel: EventEmitter<any> = new EventEmitter<any>();

  qcFilterData: DataFilters = { asset: '', field: '', well: '' };

  constructor(private sb: AppSandbox,) {
    // do nothing.
  }


}
