import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WellSelectionService {
  assetValue: string = '';
  fieldValue: string = '';

  constructor(private http: HttpClient) { }

  getAssets(aff: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/${aff}/assets`);
  }

  getFields(aff: string, asset: string): Observable<string[]> {
    return this.http.get<string[]>(`${environment.apiUrl}/${aff}/fields`, { params: { asset } });
  }

  getWells(aff: string, asset: string, field: string, wellType?: string): Observable<string[]> {

    let params = new HttpParams().set('asset', asset);
    params = params.append('field', field);
    if (wellType != undefined) {
      params = params.append('well_type', wellType);
    }
    return this.http.get<string[]>(`${environment.apiUrl}/${aff}/wells`, { params });
  }

  setAssetValue(asset: string) {
    this.assetValue = asset;
  }

  getAssetValue() {
    return this.assetValue;
  }

  setFieldValue(field: string) {
    this.fieldValue = field;
  }

  getFieldValue() {
    return this.fieldValue;
  }
}
