import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { GetMergeModelData } from './actions';


@State<string>({
  name: 'MergeModelData',
  defaults: {} as string
})
@Injectable()
export class MergeModelDataState {
  @Action(GetMergeModelData)
  getMergeModelParams(context: StateContext<string>, {payload}: GetMergeModelData): void {
    context.setState(payload);
  }
}
