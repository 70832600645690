import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ResponseI } from 'src/modules/types';

@Injectable({
  providedIn: 'root'
})
export class MergeModelService {

  constructor(private http: HttpClient) { }

  saveMergeModel(aff: string, reqObj:any): Observable<any>{
    const headers = {'content-type' : 'application/json'}
    return this.http.post<any>(`${environment.apiUrl}/${aff}/save-merge-model`, JSON.stringify(reqObj), { headers });
  }

  getSavedMergeModel(aff:string, user:string): Observable<ResponseI<any>> {
    let params:any
    if(user!=undefined && user!=''){
      debugger
      params=new HttpParams().set('username',user);
    }
    return this.http.get<ResponseI<any>>(
      `${environment.apiUrl}/${aff}/save-merge-model`,{params});
  }

  editMergeModel(aff: string, requestObject:any): Observable<ResponseI<any>> {

    const headers = { 'content-type': 'application/json' }
    return this.http.put<ResponseI<string>>(
      `${environment.apiUrl}/${aff}/save-merge-model`, JSON.stringify(requestObject), { headers });
  }

  deleteMeregeModel(aff: string, model_name:string): Observable<ResponseI<string>> {
    let params = new HttpParams().set('model_name', model_name);
    return this.http.delete<ResponseI<any>>(
      `${environment.apiUrl}/${aff}/save-merge-model`, { params });
  }
}
