import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { first, skip } from 'rxjs/operators';
import { AppSandbox } from 'src/modules/app/app.sandbox';
import { ColumnDefinitions, DataFilters, FieldRangeDates } from 'src/modules/types';
import { ModelCatalogueData } from 'src/modules/types/model-catalogue-data';
import { MODEL_CATALOGUE_COLUMN } from './catalogue-column-const';
import { Router } from '@angular/router';

@Component({
  selector: 'wag-catalogue-table',
  template: `
  <div>
  <div class="table-header-container1" *ngIf="exportAction">

    <div class="function-container">

      <button class="secondary-btn mr-1" [disabled]="enableItem" (click)="onDownloadClick()"><span>
          </span><span>Download File</span></button>
        <button class="secondary-btn mr-1" (click)="onClickCancel()"><span>
        </span><span>Cancel</span></button>


  </div>
<div *ngIf="exportAction" class="customClass">
<wag-date-filters [dateRange]="catalougeRange$| async"
                        [minMaxRange]="minMaxRange$ |async"
                        (dateRangeChange)="onDateChange($event)"
                      title="Period selected for Model Prediction"></wag-date-filters>


</div>
</div>

  <div class="table-header-container">

    <span class="function-container">
      <button *ngIf="canEdit" class="secondary-btn mr-1" (click)="edit()"><span>
          <mat-icon>edit </mat-icon>
        </span><span>Edit Models</span></button>
      <span *ngIf="canAction" class="action-container">
        <button class="secondary-btn mr-1" [disabled]="enableItem" (click)="onEditClick()"><span>
            <mat-icon>edit </mat-icon>
          </span><span>Edit</span></button>
        <button class="secondary-btn mr-1" [disabled]="enableItem" (click)="onDeleteClick()"><span>
            <mat-icon>delete </mat-icon>
          </span><span>Delete</span></button>
        <button class="secondary-btn mr-1" [disabled]="enableItem" (click)="onProductionClick()">Production</button>
        <button class="secondary-btn mr-1" [disabled]="enableItem" (click)="onArchiveClick()">Archive</button>
        <button class="secondary-btn mr-1" (click)="onCancelClick()">Cancel</button>
      </span>

      <button class="secondary-btn mr-1" *ngIf="exportHide" (click)="OnclicExport()"><span>Export</span></button>
      <button class="secondary-btn mr-1" *ngIf="true" (click)="OnClickMergeModel()"><span>Merge Model</span></button>
        <span class="col-visibility">

        </span>

    </span>


  </div>

  <div class="search-container">
    <span class="search">

      <div style="display:inline-block;margin-left: 8px;">
        <span class="caption">
          Search
        </span>

        <mat-form-field appearance="outline" >
       <input matInput  (input)="filterByAll($event)" autocomplete="off" [disabled]="hideCommanSearch">
        </mat-form-field>
        <mat-slide-toggle color="warn"  class="toggle" (change)="disableSearchFilter($event)">Filters</mat-slide-toggle>
      </div>
      <div style="display:inline-block;margin-left:16px;">
      <span class="caption">
      Show additional Columns
      </span>
      <mat-form-field appearance="outline" floatLabel="always">
      <mat-select  [formControl]="coloumnSelection" multiple [compareWith]="compareObjects" [(ngModel)]="selectedColoumn" panelClass="my-panel-class-cl"  >
       <ng-container>
        <mat-option *ngFor="let coloumn of columnList"  [value]="coloumn.colDef" [disabled]="coloumn.isEnabled" (onSelectionChange)="change($event)" [style.display]="coloumn.isEnabled ?'none':'inline-flex'" >
        <span>
        {{coloumn.label}}</span></mat-option>
       </ng-container>
      </mat-select>

      </mat-form-field>
    </div>
    </span>

    <span class="paginator-container">
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[15, 25, 35]" showFirstLastButtons></mat-paginator>
    </span>
  </div>
  <div class="table-container">
  <table *ngIf="catalogueTableData" mat-table [dataSource]="dataSourceTable" matSort
    (matSortChange)="announceSortChange($event)">

    <ng-container matColumnDef="checkbox" sticky>
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element;">
        <mat-checkbox color="warn" (change)="onClickCheckbox(element, $event)" style="padding-right: 10px !important"></mat-checkbox>

      </td>
    </ng-container>

    <ng-container matColumnDef="select" sticky>
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element;">
        <mat-radio-button color="warn" (click)="onCheckClick(element)"></mat-radio-button>

      </td>
    </ng-container>


    <ng-container matColumnDef="id" sticky >
      <th mat-header-cell *matHeaderCellDef> ID </th>
      <td mat-cell *matCellDef="let element; let i=index"> {{i+1}} </td>
    </ng-container>

    <ng-container matColumnDef="model_name" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <div class="header-container">
          Model Name
          <span mat-sort-header sortActionDescription="Sort by model_name">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline">
            <input matInput (input)="filterByColoumnName($event,'model_name')" autocomplete="off">
          </mat-form-field>
        </ng-container>
      </th>
      <td mat-cell *matCellDef="let element" (click)="gotoList(element)">{{element.model_name}} </td>
      <!-- //[routerLink]="['/model']" [queryParams]="element" -->
    </ng-container>

    <ng-container matColumnDef="well_id" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <div class="header-container">
          Well Name
          <span mat-sort-header sortActionDescription="Sort by well_id">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'well_id')"  [matAutocomplete]="autoWellId">
            <mat-autocomplete #autoWellId="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'well_id')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('well_id')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>


      </th>
      <td mat-cell *matCellDef="let element"> {{element.well_id}} </td>
    </ng-container>

    <ng-container matColumnDef="target_value" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <div class="header-container">
          Target
          <span mat-sort-header sortActionDescription="Sort by target_value">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline"  class="input-well">
            <input matInput (input)="filterByColoumnName($event,'target_value')" [matAutocomplete]="autoTarget">
            <mat-autocomplete #autoTarget="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'target_value')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('target_value')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.target_value}} </td>
    </ng-container>

    <ng-container matColumnDef="model_description">
      <th mat-header-cell *matHeaderCellDef>
        <div class="header-container">
          Description
          <span mat-sort-header sortActionDescription="Sort by model_description">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well" >
            <input matInput (input)="filterByColoumnName($event,'model_description')" [matAutocomplete]="autoDescription" >
            <mat-autocomplete #autoDescription="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'model_description')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('model_description')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.model_description}} </td>
    </ng-container>

    <ng-container matColumnDef="algorithm">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
          Model Type
          <span mat-sort-header sortActionDescription="Sort by algorithm">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'algorithm')" [matAutocomplete]="autoAlgorithm">
            <mat-autocomplete #autoAlgorithm="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'algorithm')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('algorithm')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.algorithm}} </td>
    </ng-container>

    <ng-container matColumnDef="data_input_source">

      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
          Input source
          <span mat-sort-header sortActionDescription="Sort by data_input_source">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'data_input_source')" [matAutocomplete]="autoInputSource">
            <mat-autocomplete #autoInputSource="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'data_input_source')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('data_input_source')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{!element.added_features  ? element.data_input_source : element.data_input_source + ",Added features" }} </td>
    </ng-container>

    <ng-container matColumnDef="features">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
          Features
          <span mat-sort-header sortActionDescription="Sort by features">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'features')" [matAutocomplete]="autoFeatures">
            <mat-autocomplete #autoFeatures="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'features')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('features')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>


      </th>
      <td mat-cell *matCellDef="let element"> {{!element.added_features  ? element.features : element.features + "," + element.added_features}} </td>
    </ng-container>

    <ng-container matColumnDef="target_source">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
          Target source
          <span mat-sort-header sortActionDescription="Sort by target_source">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'target_source')" [matAutocomplete]="autoTargetSource">
            <mat-autocomplete #autoTargetSource="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'target_source')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('target_source')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.target_source}} </td>
    </ng-container>

    <ng-container matColumnDef="save_model_time">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        Model Save Date
          <span mat-sort-header sortActionDescription="Sort by save_model_time">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline"  class="input-well">
            <input matInput (input)="filterByColoumnName($event,'save_model_time')"  [disabled]="true">
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.save_model_time|date}} </td>
    </ng-container>

    <ng-container matColumnDef="user_id">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        User
          <span mat-sort-header sortActionDescription="Sort by user_id">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'user_id')" [matAutocomplete]="autoUserId">
            <mat-autocomplete #autoUserId="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'user_id')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('user_id')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.user_id}} </td>
    </ng-container>

    <ng-container matColumnDef="model_status">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        Model Status
          <span mat-sort-header sortActionDescription="Sort by model_status">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'model_status')" [matAutocomplete]="autoStatus">
            <mat-autocomplete #autoStatus="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'model_status')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('model_status')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>

      <td mat-cell *matCellDef="let element"  style="font-weight:500;">
      <span *ngIf="element.model_status==='PRODUCTION'" style="font-weight:500;">{{element.model_status}}</span>
      <span *ngIf="element.model_status!=='PRODUCTION'">{{element.model_status}}</span>
      </td>


    </ng-container>

    <ng-container matColumnDef="min_date">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        Start Training Date
          <span mat-sort-header sortActionDescription="Sort by min_date">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'min_date')" [disabled]="true">
        <mat-autocomplete #autoModelName="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'min_date')">
         <mat-option *ngFor="let option of getAutoCompleteUniqueOption('min_date')" [value]="option">
              {{option}}
         </mat-option>
        </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.min_date_range}} </td>
    </ng-container>

    <ng-container matColumnDef="max_date">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        End Training Date
          <span mat-sort-header sortActionDescription="Sort by max_date">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (keyup)="filterByColoumnName($event,'max_date')" autocomplete="off" [disabled]="true">
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.max_date_range}} </td>
    </ng-container>

    <ng-container matColumnDef="ml_training_set_size">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        ML Training Size
          <span mat-sort-header sortActionDescription="Sort by ml_training_set_size">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'ml_training_set_size')" [matAutocomplete]="autoTrainingSize">
            <mat-autocomplete #autoTrainingSize="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'ml_training_set_size')">
            <mat-option *ngFor="let option of getAutoCompleteUniqueOption('ml_training_set_size')" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.ml_training_set_size}} </td>
    </ng-container>

    <ng-container matColumnDef="valid_flag">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
       Valid Flag
          <span mat-sort-header sortActionDescription="Sort by valid_flag" >
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'valid_flag')" autocomplete="off" [matAutocomplete]="autoValidFlag" >
            <mat-autocomplete #autoValidFlag="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'valid_flag')">
             <mat-option *ngFor="let option of getAutoCompleteUniqueOption('valid_flag')" [value]="option">
              {{option}}
           </mat-option>
          </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.valid_flag}} </td>
    </ng-container>

    <ng-container matColumnDef="well_test_status">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        Well Test Status
          <span mat-sort-header sortActionDescription="Sort by well_test_status">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'well_test_status')"  autocomplete="off"  [matAutocomplete]="autoWellStatus">
            <mat-autocomplete #autoWellStatus="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'well_test_status')">
             <mat-option *ngFor="let option of getAutoCompleteUniqueOption('well_test_status')" [value]="option">
             {{option}}
             </mat-option>
           </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.well_test_status}} </td>
    </ng-container>
    <ng-container matColumnDef="well_type">
      <th mat-header-cell *matHeaderCellDef>

        <div class="header-container">
        Well Type
          <span mat-sort-header sortActionDescription="Sort by well_type">
          </span>
        </div>
        <ng-container *ngIf="hideSearch">
          <mat-form-field appearance="outline" class="input-well">
            <input matInput (input)="filterByColoumnName($event,'well_type')"    [matAutocomplete]="autowell_type">
            <mat-autocomplete #autowell_type="matAutocomplete" (optionSelected)="filterByColoumnNameAuto($event,'well_type')">
             <mat-option *ngFor="let option of getAutoCompleteUniqueOption('well_type')" [value]="option">
             {{option}}
             </mat-option>
           </mat-autocomplete>
          </mat-form-field>
        </ng-container>

      </th>
      <td mat-cell *matCellDef="let element"> {{element.well_type}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="getDisplayedColumns();sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: getDisplayedColumns();"></tr>
  </table>
  </div>

</div>

  `,
  styleUrls: ['./catalogue-table.component.scss']
})
export class CatalogueTableComponent implements OnInit, OnChanges, AfterViewInit {
  assets$ = this.sb.assets$;
  fields$ = this.sb.fields$;
  @Input() assets!: string[] | null;
  @Input() fields!: string[] | null;
  @Input() filterData: any;
  affiliate!: string;
  SelectedlistTable: string[] = [];
  ListModels: any;
  // dateRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1));
  minMaxRange$: Observable<FieldRangeDates> = this.sb.fieldRangeDates$.pipe(skip(1), first());
  catalougeRange$: Observable<FieldRangeDates> = this.sb.catalogRangeDates$.pipe(skip(1));

  qcFilterData: DataFilters = { asset: '', field: '', well: '' };
  qcDateRangeSubscription: Subscription = this.catalougeRange$.subscribe(dateRange => {
    this.filterData = {
      ...this.filterData,
      date_min: new Date(dateRange.startDate),
      date_max: new Date(dateRange.endDate),
    };
  });
  @Input() catalogueTableData!: ModelCatalogueData[] | null;
  @Input() minMaxRange!: FieldRangeDates | null;
  @Input() qcDateRange!: FieldRangeDates | null;
  @Input() enableItem!: boolean;
  dataSourceTable!: MatTableDataSource<ModelCatalogueData>
  private paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatTable) table!: MatTable<any>;
  pageSize: number = 25;
  selectedElement!: ModelCatalogueData;
  @Output() clickEdit: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickDelete: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickDownload: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickProduction: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() clickArchive: EventEmitter<ModelCatalogueData> = new EventEmitter<ModelCatalogueData>();
  @Output() dateRangeChange: EventEmitter<FieldRangeDates> = new EventEmitter<FieldRangeDates>();
  @Output() clickMergeModel: EventEmitter<any> = new EventEmitter<any>();
  canEdit: boolean = true;
  exportAction: boolean = false;
  exportHide: boolean = true;
  visiblityCancel: boolean = false;
  canAction: boolean = false;
  modelname!: string;
  runID!: string;
  hideSearch: boolean = false;
  hideCommanSearch: boolean = false;
  format = "YYYY-MM-DD"
  coloumnSelection = new FormControl();
  columnList: ColumnDefinitions[] = MODEL_CATALOGUE_COLUMN;
  isMergeModelFormOpen: boolean = false;
  selectedColoumn: ColumnDefinitions[] = [{ colDef: 'checkbox', label: "", isEnabled: true, showHide: false },
  { colDef: 'select', label: "", isEnabled: true, showHide: false },
  { colDef: 'id', label: "ID", isEnabled: true, showHide: true },
  { colDef: 'model_name', label: "Model Name", isEnabled: true, showHide: true },
  { colDef: 'well_id', label: "Well Name", isEnabled: true, showHide: true },
  { colDef: 'target_value', label: "Target", isEnabled: true, showHide: true },
  { colDef: 'model_description', label: "Description", isEnabled: false, showHide: true },
  { colDef: 'algorithm', label: "Model Type", isEnabled: false, showHide: false },
  { colDef: 'data_input_source', label: "Input Source", isEnabled: false, showHide: true },
  { colDef: 'features', label: "Features", isEnabled: false, showHide: true },
  { colDef: 'target_source', label: "Target Source", isEnabled: false, showHide: true },
  { colDef: 'save_model_time', label: "Model Save Date", isEnabled: false, showHide: true },
  { colDef: 'user_id', label: "User", isEnabled: false, showHide: true },
  { colDef: 'model_status', label: "Model Status", isEnabled: false, showHide: true }];

  constructor(private sb: AppSandbox, private _liveAnnouncer: LiveAnnouncer, private router: Router) {
    this.sb.selectedAffiliate$.subscribe(affiliate => { this.affiliate = affiliate; });
  }

  change(event: any) {

    if (event.isUserInput === true) {
      this.columnList.forEach((element, index) => {
        if (element.colDef === event.source.value) {
          element.showHide = event.source.selected;
        }
      });
    }
  }

  gotoList(element:any) {
    let tableList= JSON.stringify(element)
    this.router.navigate(
      ['/model'],
      { queryParams: { tableList } }
    );

  }
  getDisplayedColumns(): string[] {

    return this.columnList.filter(cd => cd.showHide).map(cd => cd.colDef);
  }

  ngAfterViewInit() {
    if (this.table) {
      this.table.updateStickyColumnStyles();
    }
    this.dataSourceTable.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges) {
    this.dataSourceTable = new MatTableDataSource(this.catalogueTableData!);
    this.setDataSourceAttributes();
    this.enableItem = true;
    this.SelectedlistTable = [];

  }

  ngOnInit(): void {
    this.dataSourceTable = new MatTableDataSource(this.catalogueTableData!);
    this.coloumnSelection.setValue(this.selectedColoumn);
    this.SelectedlistTable = [];

  }

  @ViewChild(MatPaginator, { static: false }) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSourceTable.paginator = this.paginator;
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }


  onCheckClick(element: any) {
    this.selectedElement = element;
    this.enableItem = false;
  }

  onClickCheckbox(element: any, $event: any) {
    if ($event.checked) {
      this.selectedElement = element;
      this.enableItem = false;
      this.SelectedlistTable.push(element);
    }
    else {
      let index = this.SelectedlistTable.indexOf(element);
      if (index > -1) {
        this.SelectedlistTable.splice(index, 1);
      }

    }

  }
  onEditClick() {
    if (this.selectedElement)
      this.clickEdit.emit(this.selectedElement);
  }

  onDeleteClick() {
    if (this.selectedElement)
      this.clickDelete.emit(this.selectedElement);
  }

  onCancelClick() {
    this.columnList.forEach((element, index) => {
      if (element.colDef === 'select') {
        element.showHide = false;
        element.isEnabled = true;
      }
    });
    this.canEdit = true;
    this.canAction = false;
    this.enableItem = true;
  }


  onDownloadClick() {
    let Selectedlist: any = this.SelectedlistTable;
    type objType = {
      model_name: string;
      run_id: string;
    };
    const ArrayList1: objType[] = [];
    Selectedlist.forEach((i: any) => {
      this.modelname = i.model_name;
      this.runID = i.run_id;
      ArrayList1.push({ run_id: this.runID, model_name: this.modelname });
    })
    this.ListModels = { 'models': ArrayList1, 'prediction_min_date': moment(this.filterData.date_min).format(this.format), 'prediction_max_date': moment(this.filterData.date_max).format(this.format) }
    this.clickDownload.emit(this.ListModels);
  }
  edit() {
    this.columnList.forEach((element, index) => {
      if (element.colDef === 'select') {
        element.showHide = true;
        element.isEnabled = true;
      }
    });
    this.canEdit = false;
    this.canAction = true;
  }

  OnclicExport() {

    this.columnList.forEach((element, index) => {
      if (element.colDef === 'checkbox') {
        element.showHide = true;
        element.isEnabled = true;

      }
    });

    this.canEdit = false;
    this.canAction = false;
    this.exportAction = true;
    this.exportHide = false;
    this.sb.loadCatalogRangeDates(this.affiliate, this.filterData);
  }

  onClickCancel() {

    this.columnList.forEach((element, index) => {
      if (element.colDef == 'checkbox') {
        element.showHide = false;
        element.isEnabled = true;

      }
    });

    this.canEdit = true;
    this.exportAction = false;
    this.exportHide = true;
  }

  onDateChange(dateRange: FieldRangeDates): void {

    this.sb.setCatalogeRangeDates(dateRange);
  }
  onProductionClick() {
    if (this.selectedElement) {

      this.clickProduction.emit(this.selectedElement);
    }
  }
  onArchiveClick() {
    if (this.selectedElement) {

      this.clickArchive.emit(this.selectedElement);
    }
  }
  filterByColoumnName(eventValue: any, columnName: string) {

    let value: string = eventValue.target.value;
    let event: string = value.toLocaleLowerCase().trim();
    switch (columnName) {
      case 'model_name':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.model_name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'well_id':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.well_id.toLocaleLowerCase().indexOf(filter.toLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'target_value':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.target_value.toLocaleLowerCase().indexOf(filter.toLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'model_description':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.model_description.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'algorithm':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.algorithm.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'data_input_source':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.data_input_source.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'features':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => JSON.stringify(data.features).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'target_source':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.target_source.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'save_model_time':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.save_model_time.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'user_id':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.user_id.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'model_status':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.model_status.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'ml_training_set_size':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.ml_training_set_size.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'valid_flag':
        this.dataSourceTable.filterPredicate =
          ((data: ModelCatalogueData, filter: string) => {
            return JSON.stringify(data).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
          })
        this.dataSourceTable.filter = event;
        break;
      case 'well_test_status':
        this.dataSourceTable.filterPredicate =
          ((data: ModelCatalogueData, filter: string) => {
            return JSON.stringify(data).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
          })
        this.dataSourceTable.filter = event;
        break;
      case 'well_type':
        this.dataSourceTable.filterPredicate =
          ((data: ModelCatalogueData, filter: string) => {
            return JSON.stringify(data).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
          })
        break;
      default:
        break;
    }
  }

  filterByColoumnNameAuto(eventValue: any, columnName: string) {
    let value: string = eventValue.option.value;
    let event: string;
    if (columnName != 'valid_flag') {
      event = value.toLowerCase().trim();
    } else {
      event = value;
    }

    switch (columnName) {
      case 'model_name':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.model_name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'well_id':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.well_id.toLocaleLowerCase().indexOf(filter.toLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'target_value':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.target_value.toLocaleLowerCase().indexOf(filter.toLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'model_description':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.model_description.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'algorithm':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.algorithm.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'data_input_source':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.data_input_source.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'features':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.features.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'target_source':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.target_source.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'save_model_time':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.save_model_time.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'user_id':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.user_id.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'model_status':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.model_status.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'ml_training_set_size':
        this.dataSourceTable.filterPredicate =
          (data: ModelCatalogueData, filter: string) => data.ml_training_set_size.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
        this.dataSourceTable.filter = event;
        break;
      case 'well_type':
        this.dataSourceTable.filterPredicate =
          ((data: ModelCatalogueData, filter: string) => {
            return JSON.stringify(data).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
          })
        this.dataSourceTable.filter = event;
        break;
      case 'valid_flag':
        this.dataSourceTable.filterPredicate =
          ((data: ModelCatalogueData, filter: string) => {
            return JSON.stringify(data).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
          })
        this.dataSourceTable.filter = event;
        break;
      case 'well_test_status':
        this.dataSourceTable.filterPredicate =
          ((data: ModelCatalogueData, filter: string) => {
            return JSON.stringify(data).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
          })
        this.dataSourceTable.filter = event;
        break;

      default:
        break;
    }
  }
  filterByAll(eventValue: any) {
    let value: string = eventValue.target.value;
    let event: string = value.toLocaleLowerCase().trim();
    this.dataSourceTable.filterPredicate =
      (data: ModelCatalogueData, filter: string) => JSON.stringify(data).toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) != -1;
    this.dataSourceTable.filter = event;
  }
  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }



  disableSearchFilter(event: any) {
    this.hideCommanSearch = event.checked;
    this.hideSearch = event.checked;
  }

  getAutoCompleteUniqueOption(key: string) {
    let mapArray: any[] = [];
    if (this.catalogueTableData != undefined) {
      mapArray = this.catalogueTableData.map((data: any) => {
        if (data[key] !== '') {
          if (key === 'valid_flag') {
            return data[key] + '';
          }
          else {
            return data[key];
          }

        }
      })
      let uniqueValues = mapArray.filter((value, index, arr) => arr.indexOf(value) === index);
      let removeEmptyEntry = uniqueValues.filter((val) => val);
      return removeEmptyEntry;
    }
    else {
      return mapArray
    }
  }

  OnClickMergeModel() {
    this.isMergeModelFormOpen = true;  
    this.clickMergeModel.emit(this.filterData);
  }

}
