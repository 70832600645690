import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SaveMyAllocationComponent } from './containers/back-allocation.container/components/save-my-allocation/save-my-allocation.component';
import { AllocationChartComponent } from './containers/back-allocation.container/components/allocation-chart/allocation-chart.component';
import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {
  AppContainer,
  LoginContainer,
  SelectionComponent,
  ModelContainer,
  WellGraphComponent,
  FieldOverviewContainer,
  FieldDailyDataComponent,
  AssetOverviewContainer,
  WellOverviewContainer,
  FieldOfficialProductionComponent,
  CallbackContainer,
  UnauthorizedContainer,
  PublicLayoutContainer,
  PrivateLayoutContainer,
  HomeContainer,
  FieldDailyWellDataComponent,
  WellDataLineComponent,
  FieldDailyWellDataHeaderComponent,
  DateFiltersComponent,
  FieldWellsOptimesComponent,
  MachineLearningComponent,
  MlSettingsComponent,
  MlVisualizeComponent,
  MlConfidenceComponent,
  ModelPerformanceComponent,
  MetricsTableComponent,
  WellQcTableComponent,
  InputDataQcComponent,
  ModelInterpretabilityComponent,
  SaveMyModelComponent,
  SavedModelListComponent,
  SaveModelTablesComponent,
  AffiliateSelectionComponent,
  AdditionalLinkComponent,
  CatalogueContainerComponent,
  CatalogueSelctionComponent,
  ModelCatalogueComponent,
  CatalogueTableComponent,
  DeleteModelComponent,
  ModelStageComponent,
  BackAllocationContainerComponent,
  BackAlloctionSelectionComponent,
  AllocationSettinggsComponent,
  AllocationCatalogueComponent,
  AllocationTableComponent
} from './containers';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory} from './config';
import {HeaderComponent, CardComponent, SidenavComponent, LoaderComponent} from './components';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from '../material/material.module';
import {NgxsModule} from '@ngxs/store';
import {environment} from '@environments/environment';
import {globalState} from '../statemanagement';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxsLoggerPluginModule} from '@ngxs/logger-plugin';
import {NgxsReduxDevtoolsPluginModule} from '@ngxs/devtools-plugin';
import {DisplayNAPipe} from './pipes';
import {MAT_DATE_LOCALE} from '@angular/material/core';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {LoaderInterceptor} from './interceptors';
import {NgxSliderModule} from '@angular-slider/ngx-slider';
import {PlotlyViaWindowModule} from 'angular-plotly.js';
import { MdePopoverModule } from '@material-extended/mde';
import { MlVisualizeInjectorComponent } from './containers/model/components/machine-learning/ml-visualize-injector/ml-visualize-injector.component';
import { MergeModelComponent } from './containers/catalogue-container/components/model-catalogue/merge-model/merge-model.component';




registerLocaleData(localeFr);

@NgModule({
  declarations: [
    AppContainer,
    LoginContainer,
    CallbackContainer,
    UnauthorizedContainer,
    HeaderComponent,
    ModelContainer,
    SelectionComponent,
    WellGraphComponent,
    FieldOverviewContainer,
    CardComponent,
    FieldDailyDataComponent,
    SidenavComponent,
    AssetOverviewContainer,
    WellOverviewContainer,
    FieldOfficialProductionComponent,
    FieldDailyWellDataComponent,
    WellDataLineComponent,
    FieldDailyWellDataHeaderComponent,
    FieldWellsOptimesComponent,
    PublicLayoutContainer,
    PrivateLayoutContainer,
    HomeContainer,
    DisplayNAPipe,
    LoaderComponent,
    DateFiltersComponent,
    MachineLearningComponent,
    MlSettingsComponent,
    MlVisualizeComponent,
    MlConfidenceComponent,
    ModelPerformanceComponent,
    MetricsTableComponent,
    WellQcTableComponent,
    InputDataQcComponent,
    ModelInterpretabilityComponent,
    SaveMyModelComponent,
    SavedModelListComponent,
    SaveModelTablesComponent,
    AffiliateSelectionComponent,
    AdditionalLinkComponent,
    CatalogueContainerComponent,
    CatalogueSelctionComponent,
    ModelCatalogueComponent,
    CatalogueTableComponent,
    DeleteModelComponent,
    MlVisualizeInjectorComponent,
    ModelStageComponent,
    BackAllocationContainerComponent,
    BackAlloctionSelectionComponent,
    AllocationSettinggsComponent,
    AllocationChartComponent,
    SaveMyAllocationComponent,
    AllocationCatalogueComponent,
    AllocationTableComponent,
    MergeModelComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    BrowserAnimationsModule,
    MaterialModule,
    PlotlyViaWindowModule,
    NgxMatSelectSearchModule,
    NgxSliderModule,
    MdePopoverModule,
    ReactiveFormsModule,
    FormsModule,
    NgxsModule.forRoot(globalState, {
      developmentMode: !environment.production,
      compatibility: {
        strictContentSecurityPolicy: true,
      },
    }),
    NgxsLoggerPluginModule.forRoot({
      disabled: environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: environment.production,
    }),
  ],
  providers: [
    MsalGuard,
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'fr-FR',
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-GB',
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppContainer, MsalRedirectComponent],
  entryComponents:[SaveMyModelComponent,DeleteModelComponent,ModelStageComponent,SaveMyAllocationComponent, MergeModelComponent]
})
export class AppModule { }
